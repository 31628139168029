import { styled } from 'stitches.config';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import Error from 'Atoms/Error/Error';
import { CartError } from 'Organisms/FavoriteItem/FavoriteItem';
import { useRef } from 'react';

type Offset = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

type PropTypes = {
  setAddToCartError: (arg: CartError) => void;
  offset?: Offset;
  message: string;
};

const AddToCartError = ({
  setAddToCartError,
  offset = {},
  message = '',
}: PropTypes) => {
  const errorRef = useRef<HTMLDivElement>(null);

  useOutsideClick(errorRef, () => setAddToCartError({ message: '' }));

  return (
    <AddToCartErrorContainer
      ref={errorRef}
      css={{
        top: offset.top,
        left: offset.left,
        right: offset.right,
        bottom: offset.bottom,
      }}
    >
      <Error errorLabel={message} />
    </AddToCartErrorContainer>
  );
};

const AddToCartErrorContainer = styled('div', {
  position: 'absolute',
});

export default AddToCartError;
