import { forwardRef } from 'react';
import { styled } from 'stitches.config';
import { InfoIcon } from '../../Atoms/Icons';

type PropTypes = {
  errorLabel: string;
};

const Error = forwardRef<HTMLSpanElement, PropTypes>(({ errorLabel }, ref) => {
  return (
    <ErrorContainer ref={ref}>
      <InfoIcon space={true} size={'s'} color={'secondaryDark'} />
      {errorLabel}
    </ErrorContainer>
  );
});

const ErrorContainer = styled('span', {
  color: '$textSecondary',
  backgroundColor: '$secondary4',
  fs: 6,
  fontFamily: '$fontSecondary500',
  lineHeight: '$lh133',
  py: 2,
  px: 4,
  mb: 2,
  display: 'inline-flex',
  width: '100%',
});

export default Error;
