import VariationModel from 'Models/KexVariation/VariationModel.interface';

export const GetVariantDescriptions = (variation: VariationModel) => {
  const descriptions = [];

  if (!variation) return;

  if (variation.color && variation.color.name) {
    descriptions.push(variation.color.name);
  }

  if (variation.size && variation.size.name) {
    descriptions.push(variation.size.name);
  }
  return descriptions.join(', ');
};
