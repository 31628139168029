import { HeartIcon } from 'Atoms/Icons';
import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

type FavoriteButtonBaseType = {
  onClick?: () => void;
  css?: CSS;
  size: 's' | 'm'; // 32px, 48px
  color?: 'primary' | 'secondary';
  isActive?: boolean;
  disabled?: boolean;
};

function FavoriteButton({
  onClick = () => {},
  css,
  size,
  isActive = false,
  color = 'primary',
  disabled = false,
}: FavoriteButtonBaseType) {
  return (
    <FavoriteBtn
      aria-label={'favorite button'}
      css={css}
      onClick={() => {
        onClick();
      }}
      size={size}
      disabled={disabled}
    >
      <CircleBackground bgColor={color} size={size}>
        <HeartIcon
          color={isActive ? 'primary' : 'secondary'}
          fillColor={isActive ? 'primary' : 'transparent'}
          size={'s'}
        />
      </CircleBackground>
    </FavoriteBtn>
  );
}
export default FavoriteButton;

const FavoriteBtn = styled('button', {
  variants: {
    size: {
      s: {
        h: 8,
      },
      m: {
        h: 12,
      },
    },
    disabled: {
      true: { opacity: 0.6 },
    },
  },
});

const CircleBackground = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '50%',
  variants: {
    bgColor: {
      primary: {
        backgroundColor: '$primary2',
        '&:hover': {
          backgroundColor: '$primary2',
        },
      },
      secondary: {
        backgroundColor: '$pureWhite',
        '&:hover': {
          backgroundColor: '$secondary2',
        },
      },
    },
    size: {
      s: {
        h: 8,
        w: 8,
      },
      m: {
        h: 12,
        w: 12,
      },
    },
  },
});
