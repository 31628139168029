import KexCartModel from 'Models/Cart/KexCartModel.interface';
import { useEffect, useState } from 'react';
import Fetcher from 'Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from 'Shared/Configs/EnvConfig';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useSWR, { mutate } from 'swr';

let abortController: AbortController = new AbortController();
let hasMounted = false;
let cartUrl = '/api/cart/';

type CartReturnType = {
  cart: KexCartModel;
  isLoading: boolean;
};

export function GetMotorcycleConfiguratorCart(
  languageRoute: string,
  cartName: string
): CartReturnType {
  const tempCartObject: KexCartModel = {} as KexCartModel;
  const [cart, setCart] = useState<KexCartModel>(tempCartObject);
  const { pageCacheTime } = useAppSettingsData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: fetchedCart } = useSWR(
    `${cartUrl}GetCart?language=${languageRoute}&cartName=${cartName}`,
    FetchMotorcycleConfiguratorCart,
    {
      fallbackData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fetchedCart) {
        setCart(fetchedCart);
        setIsLoading(false);
      }
    }
  }, [fetchedCart]);

  return { cart, isLoading };
}

export async function RemoveFromMotorcycleConfiguratorCart(
  productCode: string,
  languageRoute: string,
  cartName: string
) {
  const queryParams = {
    language: languageRoute,
    code: productCode,
    cartName: cartName,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}RemoveItem?${queryString}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    const { cart } = await res.json();

    mutate(
      `${cartUrl}GetCart?language=${languageRoute}&cartName=${cartName}`,
      cart,
      false
    );
  }
}

export async function UpdateMotorcycleConfiguratorCart(
  productCode: string,
  quantity: number,
  languageRoute: string,
  cartName: string
) {
  const queryParams = {
    language: languageRoute,
    code: productCode,
    quantity: quantity.toString(),
    cartName: cartName,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}Update?${queryString}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    const { cart } = await res.json();
    mutate(
      `${cartUrl}GetCart?language=${languageRoute}&cartName=${cartName}`,
      cart,
      false
    );
    return true;
  }
  return false;
}

export async function SetQuantityInMotorcycleConfiguratorCart(
  productCode: string,
  quantity: number,
  languageRoute: string,
  cartName: string
) {
  const queryParams = {
    language: languageRoute,
    code: productCode,
    quantity: quantity.toString(),
    cartName: cartName,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}SetQuantity?${queryString}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    const { cart } = await res.json();

    mutate(
      `${cartUrl}GetCart?language=${languageRoute}&cartName=${cartName}`,
      cart,
      false
    );
  }
}

export async function EmptyMotorcycleConfiguratorCart(
  languageRoute: string,
  cartName: string
) {
  mutate(
    `${cartUrl}GetCart?language=${languageRoute}&cartName=${cartName}`,
    { numberOfItems: 0 } as KexCartModel,
    false
  );

  const res = await fetch(
    `${cartUrl}RemoveAllItems?languageRoute=${languageRoute}&cartName=${cartName}`,
    {
      method: 'DELETE',
    }
  );

  if (res.ok) {
    const { cart } = await res.json();

    mutate(
      `${cartUrl}GetCart?language=${languageRoute}&cartName=${cartName}`,
      cart,
      false
    );
  }
}

export async function MergeMotorcycleConfiguratorCartIntoDefaultCart(
  sourceCartName: string,
  languageRoute: string,
  targetCartName = 'Default'
) {
  const res = await fetch(
    `${cartUrl}MergeCarts?sourceCartName=${sourceCartName}&targetCartName=${targetCartName}&language=${languageRoute}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  if (res.ok) {
    const { cart } = await res.json();

    //Mutate motorcycle cart
    mutate(
      `${cartUrl}GetCart?language=${languageRoute}&cartName=${sourceCartName}`,
      cart,
      false
    );

    //mutate default cart
    mutate(`${cartUrl}GetCart?language=` + languageRoute, false);
  }
}

export async function SetMotorcycleInMotorcycleConfigurator(
  productCode: string,
  languageRoute: string,
  cartName: string
) {
  const queryParams = {
    cartName: cartName,
    code: productCode,
    language: languageRoute,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const res = await fetch(
    `${cartUrl}SetMotorcycleInMotorcycleConfigurator?${queryString}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  if (res.ok) {
    const { cart } = await res.json();

    mutate(
      `${cartUrl}GetCart?language=${languageRoute}&cartName=${cartName}`,
      cart,
      false
    );
  }
}

function FetchMotorcycleConfiguratorCart(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<KexCartModel, any>(url, signal, (data, resolve) => {
    resolve(data.cart);
  });
}
