import { useEffect, useState } from 'react';
import { useAppSettingsData } from '../Providers/AppSettingsProvider';
import { useTranslationData } from '../Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import Modal from '../../Organisms/Modal/Modal';
import { H2, H3 } from '../../Atoms/Typography/Headings/Heading';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import { GetFavorites } from './Favorites';
import FavoriteItem from 'Organisms/FavoriteItem/FavoriteItem';

export type PropType = {
  isOpen: boolean;
  closeFavorites: () => void;
};

function FavoritesModal({ isOpen, closeFavorites }: PropType) {
  const [renderCart, setRenderCart] = useState<boolean>(false);
  const { languageRoute } = useAppSettingsData();

  const {
    commonLabels: { loading },
    favoritesLabels: { emptyBody, emptyHeader, header },
  } = useTranslationData();
  const { favoriteList, hasFavorites, isLoading } = GetFavorites(languageRoute);

  const windowUrlChanged =
    typeof window !== 'undefined' && window.location.href;

  useEffect(() => {
    if (isOpen) {
      setRenderCart(true);
    }
  }, [isOpen]);

  useEffect(() => {
    closeFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowUrlChanged]);

  return (
    <Modal showModal={isOpen} toggle={closeFavorites} noPadding>
      <MiniCartContainer>
        {renderCart && (
          <>
            {isLoading && <>{loading}</>}

            <ProductListWrapper>
              <H2 noMargin css={StyledCartHeader}>
                {header}
              </H2>
              {!hasFavorites && (
                <EmptyCartWrapper>
                  <H3 css={StyledEmptyCartTitle}>{emptyHeader}</H3>
                  <Paragraph>{emptyBody}</Paragraph>
                </EmptyCartWrapper>
              )}

              {favoriteList.favoriteProducts.map((item) => (
                <ItemWrapper key={item.code}>
                  <FavoriteItem
                    key={item.code}
                    product={item}
                    allowEdit={false}
                    isMiniCart={false}
                    needConfirmDelete={false}
                    closeFavorites={closeFavorites}
                  />
                </ItemWrapper>
              ))}

              {favoriteList.favoriteMotorcycleConfigurations.map((item) => (
                <ItemWrapper key={item.motorcycle.code}>
                  <FavoriteItem
                    key={item.motorcycle.code}
                    product={item.motorcycle}
                    allowEdit={false}
                    isMiniCart={false}
                    needConfirmDelete={false}
                    configCart={item.motorcycleConfigurationCart}
                    closeFavorites={closeFavorites}
                  />
                </ItemWrapper>
              ))}
            </ProductListWrapper>
          </>
        )}
      </MiniCartContainer>
    </Modal>
  );
}

const StyledCartHeader = {
  mt: 4,
  mb: 3,
  px: 4,
  '@mediaMinLarge': {
    mt: 10,
    mb: 11,
    px: 6,
  },
};

const ItemWrapper = styled('div', {
  px: 4,
  '@mediaMinLarge': {
    px: 8,
  },

  borderBottomWidth: '1px',
  borderBottomColor: '$primary2',
});

const StyledEmptyCartTitle = {
  mt: 0,
  mb: 4,
  fs: 8,
  lineHeight: '$lh125',
};

const ProductListWrapper = styled('div', {
  pt: 4,
  '@mediaMinLarge': {
    pt: 6,
  },
});

const EmptyCartWrapper = styled('div', {
  px: 4,
  '@mediaMinLarge': {
    px: 6,
  },
});

const MiniCartContainer = styled('div', {
  height: '100%',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

export default FavoritesModal;
