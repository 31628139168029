import KexLink from 'Kex/KexLink';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import Price from 'Atoms/Price/Price';
import { GetCart, SetQuantity } from 'Shared/Cart/Cart';
import ProductImage from 'Atoms/Image/ProductImage';
import { styled } from 'stitches.config';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import ProductCardTitle from 'Atoms/ProductCardTitle/ProductCardTitle';
import FavoriteButton from 'Atoms/Buttons/FavoriteButton';
import {
  RemoveFavorite,
  RemoveFavoriteConfigurator,
} from 'Shared/Favorites/Favorites';
import KexCartModel from 'Models/Cart/KexCartModel.interface';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { ProductType } from 'Enums/ProductType.enum';
import AddToCartError from 'Molecules/AddCartError/AddCartError';
import useProductImage from 'Shared/Hooks/useProductImage';
import { MergeMotorcycleConfiguratorCartIntoDefaultCart } from 'Shared/MotorcycleConfiguratorCart/MotorcycleConfiguratorCart';
import { GetVariantDescriptions } from 'Shared/Common/VariationHelpers';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';

type PropTypes = {
  product: VariationModel;
  allowEdit: boolean;
  isMiniCart: boolean;
  needConfirmDelete?: boolean;
  configCart?: KexCartModel;
  closeFavorites: () => void;
};

export type CartError = {
  message: string;
};

function FavoriteItem({
  product,
  isMiniCart,
  configCart,
  closeFavorites,
}: PropTypes) {
  const [cartError, setAddToCartError] = useState<CartError>({ message: '' });
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);
  const {
    languageRoute,
    staticPages: { motorcycleConfiguratorPage, checkoutPage },
  } = useAppSettingsData();
  const { miniCartDispatch, miniCartIsOpen } = useUserStateData();
  const {
    favoritesLabels: { options, configure, addToCart, errorOutOfStock },
    productLabels: { motorcycleAlreadyInCartError },
  } = useTranslationData();
  useState<boolean>(false);
  const kexNavigate = useKexNavigate();

  const { cart } = GetCart(languageRoute);

  const {
    to,
    image,
    name,
    code,
    totalWasPrice,
    wasPrice,
    nowPrice,
    productType,
    isBuyable,
    variationImages,
  } = product;

  const { itemImage } = useProductImage(image, variationImages);

  if (!cart) {
    return <></>;
  }

  const onFavoriteClick = () => {
    configCart
      ? RemoveFavoriteConfigurator(configCart.name, languageRoute)
      : RemoveFavorite(code, languageRoute);
  };

  const handleConfigurationClick = () => {
    //Redirect user to motorcycle configurator page
    kexNavigate(
      `${motorcycleConfiguratorPage}?productcode=${product.productCode}&variantcode=${code}`
    );
  };

  const variantDescriptions = GetVariantDescriptions(product);

  const handleAddToCart = async (cart: KexCartModel) => {
    if (!isBuyable) {
      setAddToCartError({ message: errorOutOfStock });

      return;
    }

    // allow max 1 motorcycle in cart
    if (productType === ProductType.MOTORCYCLES) {
      const alreadyExists = cart.lineItems.some((cartItem) => {
        return cartItem.productType === ProductType.MOTORCYCLES;
      });

      if (alreadyExists) {
        setAddToCartError({ message: motorcycleAlreadyInCartError });

        return;
      }
    }

    if (configCart) {
      await MergeMotorcycleConfiguratorCartIntoDefaultCart(
        configCart.name,
        languageRoute,
        cart.name
      );

      kexNavigate(checkoutPage);
    } else {
      SetQuantity(code, 1, languageRoute);
      miniCartDispatch(miniCartIsOpen ? 'closeMiniCart' : 'mountAndOpen');
      closeFavorites();
    }
  };

  return (
    <CartItemContainer isMiniCart={isMiniCart}>
      <KexLink css={{ mb: 0 }} href={to} underlined={false}>
        <ImageContainer>
          <ProductImage
            src={`${itemImage.src}?preset=small-product-card-desktop`}
            alt={itemImage?.alt}
          />
        </ImageContainer>
      </KexLink>
      <Details>
        <TopWrapper>
          <StyledKexLink
            href={configCart ? '' : to}
            onClick={configCart && handleConfigurationClick}
            underlined={false}
          >
            <ProductCardTitle title={name} />
          </StyledKexLink>
          <FavoriteButton onClick={onFavoriteClick} size="s" isActive={true} />
        </TopWrapper>
        {configCart && configCart.lineItems.length !== 1 && (
          <div>{`${configCart.lineItems.length - 1} ${options} `}</div>
        )}
        <BottomWrapper>
          <div>
            {variantDescriptions && (
              <ProductVariantDescription>
                {variantDescriptions}
              </ProductVariantDescription>
            )}
            <Price
              friendlyWasPrice={totalWasPrice?.priceRoundedAsString}
              friendlyNowPrice={nowPrice?.priceRoundedAsString}
              wasPrice={wasPrice?.price}
              nowPrice={nowPrice?.price}
              direction="columnReverse"
              nowPriceSize={{ fs: isMobile ? 6 : 8 }}
              wasPriceSize={{ fs: isMobile ? 6 : 8 }}
            />
          </div>
          <ButtonWrapper>
            {product.isMotorcycle && !product.isUsed && !configCart ? (
              <AddToCart onClick={handleConfigurationClick}>
                {configure}
              </AddToCart>
            ) : (
              <>
                {cartError.message !== '' && (
                  <AddToCartError
                    setAddToCartError={setAddToCartError}
                    offset={{ left: 0, right: 0, bottom: 16 }}
                    message={cartError.message}
                  />
                )}

                <AddToCart onClick={() => handleAddToCart(cart)}>
                  {addToCart}
                </AddToCart>
              </>
            )}
          </ButtonWrapper>
        </BottomWrapper>
      </Details>
    </CartItemContainer>
  );
}

const StyledKexLink = styled(KexLink, {
  maxW: '80%',
  fontFamily: '$fontSecondary600',
  wordSpacing: 'unset',
});

const AddToCart = styled('div', {
  fs: 6,
  color: '$primaryText',
  cursor: 'pointer',
  textDecoration: 'underline',
});

const ButtonWrapper = styled('div', {
  fs: 6,
  display: 'flex',
  color: '$textPrimary',
  marginTop: '16px',
  gap: '16px',
});

const CartItemContainer = styled('div', {
  display: 'flex',
  position: 'relative',

  '&:not(:last-child)': {
    py: 5,
    borderBottomWidth: '1px',
    borderBottomColor: '$checkoutPageBackgroundPrimary',
  },
  '&:last-child': {
    pt: 5,
    mb: 6,
  },
  variants: {
    isMiniCart: {
      true: {
        '&:not(:last-child)': {
          py: 5,
          borderBottomWidth: '1px',
          borderBottomColor: '$primary2',
        },
      },
    },
  },
});

const ImageContainer = styled('div', {
  width: 104,
  height: 80,
  mr: 4,
});

const TopWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const BottomWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

const Details = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const ProductVariantDescription = styled('span', {
  fs: 6,
  display: 'block',
  mb: 2,
  color: '$cartItemText',
});

export default FavoriteItem;
