import { isEmpty } from 'Shared/Common/Helpers';
import ImageModel from 'Models/Assets/ImageModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

const useProductImage = (image: ImageModel, variationImages: ImageModel[]) => {
  const { missingImage } = useAppSettingsData();

  let itemImage = image;
  if (isEmpty(image) && !isEmpty(variationImages)) {
    itemImage = variationImages[0];
  }

  if (isEmpty(itemImage)) {
    itemImage = missingImage;
  }

  return { itemImage };
};

export default useProductImage;
